<template>
  <div class="px-3 py-4">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Support Email</h1>
    </div>
    <div class="forms mt-4" v-loading="loaders.supportEmails">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" @submit.prevent="submitForm">
          <card type="default" header-classes="bg-transparent">
            <div class="pb-3">
              <h1>Baritastic Support</h1>
              <span>Enter the Baritastic Support email address below to receive help messages and inquiries.</span>
              <div class="row mt-3">
                <div class="col-lg-6 d-flex" v-for="(email, index) in emails.baritastic_support">
                  <base-input
                    :required="true"
                    type="email"
                    class="w-100 support-email-input"
                    name="Email"
                    label="Email*"
                    v-model="emails.baritastic_support[index].email"
                    placeholder="">
                  </base-input>
                  <img
                    class="pointer ml-2 margin-top-40"
                    width="28"
                    height="28"
                    @click="clearEmail(index, 'baritastic_support')"
                    src="/img/icons/buttons/remove.svg"
                    alt="Delete"
                  />
                </div>
              </div>
              <span class="add-more-email-text pointer" @click="addEmail('baritastic_support')">
                {{ getAddButtonText('baritastic_support') }}
              </span>
            </div>
            <hr class="my-4">
            <div class="pb-3 pt-2">
              <h1>Support Group Assistance</h1>
              <span>Enter the Support Group Assistance email address below to receive help messages and inquiries.</span>
              <div class="row mt-3">
                <div class="col-lg-6 d-flex" v-for="(email, index) in emails.support_group">
                  <base-input
                    :required="true"
                    type="email"
                    class="w-100 support-email-input"
                    name="Email"
                    label="Email*"
                    v-model="emails.support_group[index].email"
                    placeholder="">
                  </base-input>
                  <img
                    class="pointer ml-2 margin-top-40"
                    width="28"
                    height="28"
                    @click="clearEmail(index, 'support_group')"
                    src="/img/icons/buttons/remove.svg"
                    alt="Delete"
                  />
                </div>
              </div>
              <span class="add-more-email-text pointer" @click="addEmail('support_group')">
                {{ getAddButtonText('support_group') }}
              </span>
            </div>
            <hr class="my-4">
            <div class="pt-2">
              <h1>Request Vitamin Samples</h1>
              <span>Enter the Request Vitamin Samples email address below to receive help messages and inquiries.</span>
              <div class="row mt-3">
                <div class="col-lg-6 d-flex" v-for="(email, index) in emails.vitamin_supplement">
                  <base-input
                    :required="true"
                    type="email"
                    class="w-100 support-email-input"
                    name="Email"
                    label="Email*"
                    v-model="emails.vitamin_supplement[index].email"
                    placeholder="">
                  </base-input>
                  <img
                    class="pointer ml-2 margin-top-40"
                    width="28"
                    height="28"
                    @click="clearEmail(index, 'vitamin_supplement')"
                    src="/img/icons/buttons/remove.svg"
                    alt="Delete"
                  />
                </div>
              </div>
              <span class="add-more-email-text pointer" @click="addEmail('vitamin_supplement')">
                {{ getAddButtonText('vitamin_supplement') }}
              </span>
            </div>
          </card>
        </form>
        <base-button
          @click="submitForm"
          class="btn-secondary-action px-5"
          native-type="submit"
          type="submit">
          Update
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton"
import {extend} from "vee-validate"
import {email} from "vee-validate/dist/rules"

extend("email", {
    ...email, message: (field) => {
      return 'The email field must have a valid email address.'
    }
  }
)
export default {
  name: "SupportEmail",
  components: {
    BackButton
  },
  data() {
    return {
      loaders: {
        supportEmails: false,
      },
      emails: {
        baritastic_support: [],
        support_group: [],
        vitamin_supplement: []
      }
    }
  },
  mounted() {
    this.getSupportEmails()
  },
  methods: {
    /**
     * Get Add Button Text
     * @param type
     * @returns {string}
     */
    getAddButtonText(type) {
      return this.emails[type].length ? '+ Add More Email' : '+ Add Email'
    },

    /**
     * Get Support Emails
     */
    getSupportEmails() {
      let vm = this
      vm.loaders.supportEmails = true
      vm.$store.dispatch('SupportEmailAndMessageModule/_getAllSupportEmails')
        .then(response => {
          if (response.data.data) vm.processEmailsData(response.data.data)
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Support Email',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.supportEmails = false
        })
    },

    /**
     * Submit Emails Form
     * @returns {Promise<void>}
     */
    async submitForm() {
      let vm = this
      const isFormValid = await this.$refs['formValidator'].validate()
      if (isFormValid) {
        vm.updateSupportEmails()
      } else {
        vm.$notify.error({
          title: 'Validation Error',
          message: 'Please enter a valid email address.'
        })
      }
    },

    /**
     * Update Support Emails
     */
    updateSupportEmails() {
      let vm = this
      vm.loaders.supportEmails = true
      vm.$store.dispatch('SupportEmailAndMessageModule/_updateSupportEmails', {formData: this.emails})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Support Emails Updated Successfully!'
          })
          vm.$router.push({name: 'admin.dashboard'})
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Support Email',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.supportEmails = false
        })
    },

    /**
     * Process Emails according to type
     * @param emails
     */
    processEmailsData(emails) {
      this.emails.baritastic_support = emails.filter(email => email.type === 'baritastic_support')
      this.emails.support_group = emails.filter(email => email.type === 'support_group')
      this.emails.vitamin_supplement = emails.filter(email => email.type === 'vitamin_supplement')
    },

    /**
     * Clear Email from list
     * @param index
     * @param type
     */
    clearEmail(index, type) {
      this.emails[type].splice(index, 1)
    },

    /**
     * Add Email to list
     * @param type
     */
    addEmail(type) {
      this.emails[type].push({
        type: type,
        email: null
      })
    },
  }
}
</script>

<style scoped>

.add-more-email-text {
  font: normal normal 600 14px/19px Open Sans;
  color: #2F597A;
}

.margin-top-40 {
  margin-top: 40px;
}

</style>

<style>
.support-email-input > .form-group {
  margin-bottom: 7px !important;
}
</style>
